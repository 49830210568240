import React from 'react';

import "./footer.scss";

function Footer() {
  return (
    <div className='footer'>Axan Similien ©2025</div>
  )
}

export default Footer